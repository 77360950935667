import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

const brandingStore = (set, get) => ({
    // State properties
    heroImage: '',
    displayName: '',
    catchPhrase: '',
    tenantLogo: '',
    agentName: '',     // Changed from AgentName
    agentPhone: '',    // Changed from AgentPhone
    agentEmail: '',    // Changed from AgentEmail
    agentLogo: '',     // Changed from AgentLogo

    // Setters
    setHeroImage: (imageUrl) => {
        set((state) => ({
            heroImage: imageUrl,
        }));
    },
    setCatchPhrase: (phrase) => {
        set((state) => ({
            catchPhrase: phrase,
        }));
    },
    setDisplayName: (name) => {
        set((state) => ({
            displayName: name,
        }));
    },
    setAgentName: (memberName) => {
        set((state) => ({
            agentName: memberName,  // Fixed property name
        }));
    },
    setAgentPhone: (memberPhone) => {
        set((state) => ({
            agentPhone: memberPhone,  // Fixed property name
        }));
    },
    setAgentEmail: (memberEmail) => {
        set((state) => ({
            agentEmail: memberEmail,  // Fixed property name
        }));
    },
    setAgentLogo: (memberLogo) => {
        set((state) => ({
            agentLogo: memberLogo,  // Fixed from AgentName to agentLogo
        }));
    },
    setTenantLogo: (tenantLogo) => {
        set((state) => ({
            tenantLogo: tenantLogo,
        }));
    },
});

const useBrandingStore = create(brandingStore);

export default useBrandingStore;