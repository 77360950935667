import React, { useState, useEffect } from "react";

// react-router components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";
//import { IdentityHttpClient } from 'providers/IdentityHttpClient';

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";

import UserIcon from '@mui/icons-material/AccountCircle';
import NotificationIcon from '@mui/icons-material/Notifications';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
} from "./styles";

// Material Dashboard 2 React context
import {
  useMaterialUIController,
  setTransparentNavbar,
  setOpenUserProfile,
} from "context";

function Navbar({ absolute = false, light = false, isMini = false, brandImage, brandDisplayName = "", brandCatchPhrase = "" }) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openNotifications, openUserProfile, darkMode } = controller;
  const [openUserMenu, setOpenUserMenu] = useState(false);

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleUserProfileOpen = () => setOpenUserProfile(dispatch, !openUserProfile);

  const [openPicDialog, setPicDialogOpen] = React.useState(false);

  const handleClickPicDialogOpen = () => {
    setPicDialogOpen(true);
  }

  const handlePicDialogClose = () => {
    setPicDialogOpen(false);
  }

  // Styles for the navbar icons
  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  return (
    <AppBar
          position="relative"
          color="inherit"
          sx={(theme) => ({
              ...navbar(theme, { transparentNavbar, absolute, light, darkMode }),
              position: 'relative !important', // Override any other position settings
              zIndex: 1
          })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <MDBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
          <Grid container spacing={3} alignItems="center">
            <Grid item>
              <MDAvatar src={brandImage} alt="profile-image" size="xl" shadow="sm" />
            </Grid>
            <Grid item>
              <MDBox height="100%" mt={0.5} lineHeight={1}>
                <MDTypography variant="h4" fontWeight="medium">
                  {brandDisplayName}
                </MDTypography>
                <MDTypography variant="button" color="text" fontWeight="regular">
                  {brandCatchPhrase}
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </Toolbar>
    </AppBar>
  );
}

// Typechecking props for the DashboardNavbar
Navbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default Navbar;
