import React, { useState, useEffect } from 'react';
import { ApplicantApiClient } from 'providers/ApplicantApiClient';
import { Connect } from 'connect-web-sdk';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from "@mui/material/Grid";
import CheckIcon from '@mui/icons-material/CheckCircle';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import Stack from "@mui/material/Stack";
import MDBox from "components/MDBox";
import MDTypography from 'components/MDTypography';

import MDButton from 'components/MDButton';


const FinicityPage = ({ todoId, onBack, onCompleted }) => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [isConnectDone, setIsConnectDone] = useState(false);
    const [state, setState] = useState(null);

    useEffect(() => {

        ApplicantApiClient.get('api/applicant/finicity/' + todoId)
        .then((data) => {
            setState(data);
            setIsLoading(false);

        })
        .catch((error) => {

            setIsLoading(false);
        });
    }, []);


    const postLogEntry = (event) => {
        ApplicantApiClient.post(`/api/applicant/todoitem/${todoId}/events`, event)
        .then(() => {
            console.log('event written');
        })
        .catch((error) => { 
            console.error(error);
        });
    };

    const connectEventHandlers = {
        onDone: (event) => { handleDone(event); },
        onCancel: (event) => { postLogEntry(event); },
        onError: (event) => { postLogEntry(event); },
        onRoute: (event) => { postLogEntry(event); },
        onUser: (event) => { postLogEntry(event); },
        onLoad: () => { postLogEntry('loaded'); }
      };

    const connectOptions = {
        overlay: 'rgba(199,201,199, 0.5)',
        windowOptions: {
            position: {
                zIndex: 999999 // Set a very high z-index to ensure it's above the header
            }
        }
      };

    const launchConnect = () => {
        Connect.launch( state.connectUrl, connectEventHandlers, connectOptions );
    }

    const handleDone = (event) => {
        setIsLoading(true);
        console.log("all done!");
        postLogEntry(event);
        ApplicantApiClient.put('api/applicant/finicity/' + todoId, state)
        .then((data) => {
            setIsLoading(false);
            setIsConnectDone(true);
        })
        .catch((error) => {
            setIsLoading(false);

        });
    }
    
    const onSubmit = () => {
        if (onCompleted) {
            onCompleted();
        }
    };

    const handleUploadRedirect = () => {
        if (onCompleted) {
            onCompleted();
        }
    };



    return (
        <Grid container>
            <Grid item xs={12} lg={12} sx={{ textAlign: "center", alignItems: "center", margin: "auto" }} >
                {isConnectDone &&
                    <>
                        <Grid container>
                            <Grid item xs={3} lg={3}>
                                <MDBox
                                    justifyContent="center"
                                    alignItems="center"
                                    width="9rem"
                                    height="9rem"
                                    variant="gradient"
                                    bgColor="success"
                                    color="white"
                                    shadow="md"
                                    borderRadius="xl"
                                    mt={4}
                                    ml={20}
                                >
                                    <CheckIcon sx={{ width: "8rem", height: "8rem" }} />
                                </MDBox>
                            </Grid>
                            <Grid item xs={9} lg={9} mt={5}>
                                <MDTypography variant="h6">Account Link Setup!</MDTypography>
                                <MDTypography variant="caption">Please hit "Next" to continue</MDTypography>
                            </Grid>
                        </Grid>
                    </>
                }
                {!isConnectDone &&
                    <>
                        <MDBox lineHeight={0}>
                            <MDTypography variant="h5">Link Account</MDTypography>
                            <MDTypography variant="button" color="text">
                                Connect your accounts securely using Finicity or manually upload your statements.
                            </MDTypography>
                        </MDBox>
                        <MDBox mt={3}>
                            <Stack spacing={2} direction="row" justifyContent="center">
                                {isLoading ? (
                                    <CircularProgress />
                                ) : (
                                    <>
                                        <MDButton
                                            onClick={launchConnect}
                                            variant="gradient"
                                            color="primary"
                                            startIcon={<CheckIcon />}
                                        >
                                            Connect with Finicity
                                        </MDButton>
                                       
                                    </>

                                )}
                            </Stack>
                        </MDBox>
                    </>
                }
            </Grid>
            {isConnectDone && (
                <Grid item xs={12} lg={12}>
                    <MDBox mt={2} width="100%" display="flex" justifyContent="right">
                        <MDButton
                            type="button"
                            variant="gradient"
                            color="dark"
                            onClick={onSubmit}
                        >
                            Next
                        </MDButton>
                    </MDBox>
                </Grid>
            )}
        </Grid>
    )
}

export default FinicityPage;