import React, { useState, useEffect } from 'react';
import { ApplicantApiClient } from 'providers/ApplicantApiClient';
import { usePlaidLink } from 'react-plaid-link';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import CircularProgress from '@mui/material/CircularProgress';

import Grid from "@mui/material/Grid";
import CheckIcon from '@mui/icons-material/CheckCircle';

import MDBox from "components/MDBox";
import Stack from "@mui/material/Stack";
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';

const PlaidPage = ({ todoId, onBack, onCompleted}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isLinkDone, setIsLinkDone] = useState(false);
    const [state, setState] = useState(null);
    const [plaidToken, setPlaidToken] = useState('');

    const { open, ready } = usePlaidLink({
        token: plaidToken,
        onSuccess: (public_token, metadata) => {
          setIsLoading(true);

          const updatePlaidInfo = {
            linkToken: plaidToken,
            publicToken: public_token,
          };

          ApplicantApiClient.put(`/api/applicant/plaid/${todoId}`, updatePlaidInfo)
            .then((data) => {
                setIsLoading(false);
                setIsLinkDone(true);
            })
            .catch((error) => {
                setIsLoading(false);
                console.error(error);
            });
        },
      });

    useEffect(() => {
        ApplicantApiClient.get('api/applicant/plaid/' + todoId)
        .then((data) => {
            setPlaidToken(data.linkToken);
            setState(data);
            setIsLoading(false);
        })
        .catch((error) => {
  
            setIsLoading(false);
        });
    }, []);

    const postLogEntry = (event) => {
        ApplicantApiClient.post(`/api/applicant/todoitem/${todoId}/events`, event)
        .then(() => {

        })
        .catch((error) => {
             });
    };

    const launchLink = () => {
        open();
    }
    
    const onSubmit = () => {

        const updatePlaidInfo = {
            linkToken: plaidToken,
        };

        ApplicantApiClient.put(`/api/applicant/plaid/${todoId}/complete`, updatePlaidInfo)
            .then((data) => {
                setIsLoading(false);

                if (onCompleted) {
                    onCompleted();
                }
            })
            .catch((error) => {
                setIsLoading(false);
                
            });
    };


    const handleUploadRedirect = async () => {
        try {
            // Skip current todo and move to statements upload
            await ApplicantApiClient.put(`/api/applicant/todoitem/${todoId}/skip`);
            if (onCompleted) {
                onCompleted();
            }
        } catch (error) {
            console.error('Error skipping to upload:', error);
        }
    };

    return (
        <Grid container>
            <Grid item xs={12} lg={12} sx={{ textAlign: "center", alignItems: "center", margin: "auto" }} >
                {isLinkDone &&
                    <>
                        <Grid container>
                            <Grid item xs={3} lg={3}>
                                <MDBox
                                    justifyContent="center"
                                    alignItems="center"
                                    width="9rem"
                                    height="9rem"
                                    variant="gradient"
                                    bgColor="success"
                                    color="white"
                                    shadow="md"
                                    borderRadius="xl"
                                    mt={4}
                                    ml={20}
                                >
                                    <CheckIcon sx={{ width: "8rem", height: "8rem" }} />
                                </MDBox>
                            </Grid>
                            <Grid item xs={9} lg={9} mt={5}>
                                <MDTypography variant="h6">Account Link Setup!</MDTypography>
                                <MDTypography variant="caption">Please hit "Next" to continue</MDTypography>
                            </Grid>
                        </Grid>
                    </>
                }
                {!isLinkDone &&
                    <>
                        <MDBox lineHeight={0}>
                            <MDTypography variant="h5">Link Account</MDTypography>
                            <MDTypography variant="button" color="text">
                                Connect your accounts securely using Plaid or manually upload your statements.
                            </MDTypography>
                        </MDBox>
                        <MDBox mt={3}>
                            <Stack spacing={2} direction="row" justifyContent="center">
                                {isLoading ? (
                                    <CircularProgress />
                                ) : (
                                    <>
                                        <MDButton
                                            onClick={launchLink}
                                            variant="gradient"
                                            color="primary"
                                            startIcon={<CheckIcon />}
                                        >
                                            Connect with Plaid
                                        </MDButton>
                                        <MDButton
                                            onClick={handleUploadRedirect}
                                            variant="outlined"
                                            color="primary"
                                            startIcon={<UploadFileIcon />}
                                        >
                                            Upload Statements Manually
                                        </MDButton>
                                    </>
                                )}
                            </Stack>
                        </MDBox>
                    </>
                }
            </Grid>
            {isLinkDone && (
                <Grid item xs={12} lg={12}>
                    <MDBox mt={2} width="100%" display="flex" justifyContent="right">
                        <MDButton
                            type="button"
                            variant="gradient"
                            color="dark"
                            onClick={onSubmit}
                        >
                            Next
                        </MDButton>
                    </MDBox>
                </Grid>
            )}
        </Grid>
    )
}


export default PlaidPage;