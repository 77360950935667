import React, { useState, useEffect } from 'react';
import { ApplicantApiClient } from '../providers/ApplicantApiClient';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CircularProgress from '@mui/material/CircularProgress';
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import MDBox from "components/MDBox";
import ContactInfoPage from './ContactInfo';
import FinicityPage from './Finicity';
import PlaidPage from './Plaid';
import StatementsUploadPage from './StatementsUpload';
import SuccessPage from './Demo/Success';

const ConditionalWizard = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [todoItems, setTodoItems] = useState([]);
    const [currentTodoItem, setCurrentTodoItem] = useState(null);
    const [activeStep, setActiveStep] = useState(0);

    const fetchTodoItems = async () => {
        try {
            setIsLoading(true);
            const data = await ApplicantApiClient.get('/api/applicant/todoitems');
            console.log('Todo Items:', data);
            setTodoItems(data);

            // Find the first incomplete todo item in order (ContactInfo first, then others)
            const contactInfoTodo = data.find(item =>
                item.definitionId === "ContactInfo" && !item.isComplete
            );

            if (contactInfoTodo) {
                setCurrentTodoItem(contactInfoTodo);
                setActiveStep(data.indexOf(contactInfoTodo));
            } else {
                const nextIncompleteTodo = data.find(item => !item.isComplete);
                if (nextIncompleteTodo) {
                    setCurrentTodoItem(nextIncompleteTodo);
                    setActiveStep(data.indexOf(nextIncompleteTodo));
                } else {
                    setCurrentTodoItem(null);
                    setActiveStep(data.length); // Set to length for Success step
                }
            }

        } catch (error) {
            console.error('Error fetching todo items:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchTodoItems();
    }, []);

    const handleComplete = async () => {
        await fetchTodoItems();
    };

    const renderCurrentPage = () => {
        // If no current todo item and all are complete, show success
        if (!currentTodoItem) {
            return (
                <SuccessPage
                    todoId={todoItems[0]?.todoId}
                    onReset={fetchTodoItems}
                    todoItems={todoItems}
                />
            );
        }

        // Otherwise, render the appropriate page based on the current todo item
        switch (currentTodoItem.definitionId) {
            case "ContactInfo":
                return (
                    <ContactInfoPage
                        todoId={currentTodoItem.todoId}
                        onCompleted={handleComplete}
                    />
                );
            case "Finicity":
                return (
                    <FinicityPage
                        todoId={currentTodoItem.todoId}
                        onCompleted={handleComplete}
                    />
                );
            case "Plaid":
                return (
                    <PlaidPage
                        todoId={currentTodoItem.todoId}
                        onCompleted={handleComplete}
                    />
                );
            case "Statements":
                return (
                    <StatementsUploadPage
                        todoId={currentTodoItem.todoId}
                        onCompleted={handleComplete}
                    />

                );
            default:
                return <div>Unknown todo item type</div>;
        }
    };

    if (isLoading) {
        return (
            <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%" }}>
                <CircularProgress />
            </Grid>
        );
    }

    return (
        <MDBox py={3} mb={20} height="65vh">
            <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%", mt: { xs: 2, md: 6 } }}>
                <Grid item xs={12} sm={12} lg={8}>
                    <Card sx={{ height: "100%" }}>
                        <MDBox mx={2}>
                            <Stepper activeStep={activeStep} alternativeLabel sx={{ display: { xs: 'none', md: 'flex' } }}>
                                {todoItems.map((item) => (
                                    <Step key={item.todoId}>
                                        <StepLabel>{item.title}</StepLabel>
                                    </Step>
                                ))}
                                <Step key="done">
                                    <StepLabel>Done</StepLabel>
                                </Step>
                            </Stepper>
                        </MDBox>
                        <Grid container spacing={3} alignItems="center" mb={2} pl={5} pr={5} width="100%">
                            <Grid item xs={12} lg={12}>
                                <MDBox mt={0.5} lineHeight={1}>
                                    {renderCurrentPage()}
                                </MDBox>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
        </MDBox>
    );
};

export default ConditionalWizard;