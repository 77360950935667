import React, { useState, useEffect, useCallback } from 'react';
import { ApplicantApiClient } from 'providers/ApplicantApiClient';
import { TenantApiClient } from 'providers/TenantApiClient';
import useBrandingStore from 'store/brandStore';

import Grid from "@mui/material/Grid";
import CircularProgress from '@mui/material/CircularProgress';

import MDBox from "components/MDBox";
import MDTypography from 'components/MDTypography';
import MDAvatar from 'components/MDAvatar';
import MDButton from 'components/MDButton';
import TextField from 'components/Form/TextField';
import { Formik, Form } from "formik";
import * as Yup from 'yup';

const contactInfoSchema = Yup.object().shape({
    firstName: Yup.string()
        .min(1, 'First Name must be longer')
        .max(150, 'First Name is too long.')
        .required('First Name is required'),
    lastName: Yup.string()
        .min(1, 'Last Name must be longer')
        .max(150, 'Last Name is too long')
        .required('Last Name is required'),
    email: Yup.string()
        .email('Invalid email')
        .required('Email is required'),
    mobilePhone: Yup.string()
        .min(10, 'Phone number must be longer')
        .max(14, 'Phone number too long')
        .required('Mobile Phone is required')
});

const formatPhoneNumber = (value) => {
    const cleaned = value.replace(/\D/g, "");
    if (cleaned.length <= 3) {
        return `(${cleaned}`;
    } else if (cleaned.length <= 6) {
        return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3)}`;
    } else {
        return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(6, 10)}`;
    }
};

const ContactInfoPage = ({ todoId, onBack, onCompleted }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [contactInfo, setContactInfo] = useState({
        firstName: "",
        lastName: "",
        email: "",
        mobilePhone: ""
    });

    // Get branding store functions with corrected property names
    const setAgentName = useBrandingStore((state) => state.setAgentName);
    const setAgentLogo = useBrandingStore((state) => state.setAgentLogo);
    const setAgentPhone = useBrandingStore((state) => state.setAgentPhone);
    const setAgentEmail = useBrandingStore((state) => state.setAgentEmail);

    // Get state with corrected property names
    const agentName = useBrandingStore((state) => state.agentName);
    const agentLogo = useBrandingStore((state) => state.agentLogo);
    const agentEmail = useBrandingStore((state) => state.agentEmail);
    const agentPhone = useBrandingStore((state) => state.agentPhone);
    const tenantLogo = useBrandingStore((state) => state.heroImage);

    const fetchData = useCallback(async () => {
        try {
            setIsLoading(true);

            // Fetch applicant contact info
            const data = await ApplicantApiClient.get(`api/applicant/contactInfo/${todoId}`);

            // Format mobile phone if present
            if (data.mobilePhone) {
                data.mobilePhone = formatPhoneNumber(data.mobilePhone);
            }

            setContactInfo(data);

            // Fetch member info and update the branding store
            const memberInfo = await TenantApiClient.get('api/members/info');


            if (memberInfo) {
                // Construct full name from first and last name
                const fullName = [memberInfo.firstName, memberInfo.lastName]
                    .filter(Boolean)
                    .join(" ");

                setAgentName(fullName);
                setAgentLogo(memberInfo.profileImage || '');
                setAgentEmail(memberInfo.email || '');
                setAgentPhone(memberInfo.mobilePhone ? formatPhoneNumber(memberInfo.mobilePhone) : '');
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setIsLoading(false);
        }
    }, [todoId, setAgentName, setAgentLogo, setAgentEmail, setAgentPhone]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const onSubmit = async (values) => {
        try {
            // Remove formatting before submitting
            const formattedValues = {
                ...values,
                mobilePhone: values.mobilePhone.replace(/\D/g, "")
            };

            await ApplicantApiClient.put('api/applicant/contactInfo/' + todoId, formattedValues);

            if (onCompleted) {
                onCompleted();
            }
        } catch (error) {
            console.error('Submit error:', error);
        }
    };

    if (isLoading) {
        return (
            <div>
                <CircularProgress color="dark" />
            </div>
        );
    }

    return (
        <Formik
            initialValues={contactInfo}
            validationSchema={contactInfoSchema}
            onSubmit={onSubmit}
            enableReinitialize
        >
            {({ values, setFieldValue, errors, touched, isSubmitting }) => (
                <Form>
                    <MDBox>
                        <MDBox width="82%" textAlign="center" mx="auto" my={4}>
                            <MDTypography variant="body2" color="text" mb={3}>
                                Greetings! We are excited to help you with your application.
                            </MDTypography>
                            <MDBox mb={1}>
                                <MDTypography variant="h5" fontWeight="regular">
                                    Let&apos;s start with the basic contact information.
                                </MDTypography>
                            </MDBox>
                        </MDBox>
                        <MDBox mt={2}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={4} container justifyContent="center" alignItems="center">
                                    <MDBox
                                        display="flex"
                                        flexDirection="column"
                                        alignItems="center"
                                        textAlign="center"
                                        mx="auto"
                                        p={2}
                                    >
                                        <MDAvatar
                                            src={agentLogo || tenantLogo  || ''}
                                            alt="lending agent"
                                            size="xxl"
                                            shadow="md"
                                        />
                                        <MDBox mt={2}>
                                            <MDTypography variant="body2" color="text" mt={2}>
                                                Agent: {agentName || 'Your Lending Agent'}
                                            </MDTypography>
                                    {agentEmail && (
                                                <MDTypography variant="body2" color="text" mt={1}>
                                                          Email: {agentEmail}
                                                </MDTypography>
                                            )}
                                         {agentPhone && (
                                                <MDTypography variant="body2" color="text" mt={0.5}>
                                               Number:  {agentPhone}
                                                </MDTypography>
                                            )}
                                        </MDBox>
                                    </MDBox>
                                </Grid>


                                <Grid item xs={12} sm={8} container direction="column" alignItems="center">
                                    <MDBox width="100%" maxWidth="400px">
                                        <MDBox mb={2}>
                                            <TextField
                                                fullWidth
                                                name="firstName"
                                                type="text"
                                                label="First Name"
                                            />
                                        </MDBox>
                                        <MDBox mb={2}>
                                            <TextField
                                                fullWidth
                                                name="lastName"
                                                type="text"
                                                label="Last Name"
                                            />
                                        </MDBox>
                                        <MDBox mb={2}>
                                            <TextField fullWidth name="email" type="email" label="Email Address" />
                                        </MDBox>
                                        <MDBox>
                                            <TextField
                                                fullWidth
                                                name="mobilePhone"
                                                type="text"
                                                label="Mobile Phone"
                                                value={values.mobilePhone}
                                                onChange={(e) => {
                                                    const formattedPhone = formatPhoneNumber(e.target.value);
                                                    setFieldValue("mobilePhone", formattedPhone);
                                                }}
                                            />
                                        </MDBox>
                                    </MDBox>
                                </Grid>
                            </Grid>
                        </MDBox>
                        <MDBox mt={2} width="100%" display="flex" justifyContent="center">
                            <MDButton
                                disabled={isSubmitting}
                                type="submit"
                                variant="gradient"
                                color="dark"
                            >
                                Get Started
                            </MDButton>
                        </MDBox>
                    </MDBox>
                </Form>
            )}
        </Formik>
    );
};

export default ContactInfoPage;