import React, { useEffect } from "react";
import { useAuth } from "react-oidc-context";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { ApplicationProvider } from "./contexts/ApplicationContext";
import theme from "./assets/theme";
import Navbar from "./components/Navbar";
import DemoPage from "./pages/Demo/DemoMode";
import NewWizardPage from "./pages/Demo/NewWizard";
import WizardPage from "./pages/Wizard";
import useBrandingStore from "./store/brandStore";
import SuccessPage from "pages/Demo/Success";

import { TenantApiClient } from "providers/TenantApiClient";
import StatementsUploadPage from "./pages/StatementsUpload";



const router = createBrowserRouter([
    {
        path: "/",
        element: <WizardPage />,
    },
    {
        path: "/demo",
        element: <NewWizardPage />,
    },
    {
        path: "/success",  // Add this route
        element: <SuccessPage />,

    },
    {
        path: "/statements",  // Add this route
        element: <StatementsUploadPage />,
    }
]);

function App() {
    const auth = useAuth();
 //   const { setDisplayName, setHeroImage } = useBrandingStore();
    const queryParams = new URLSearchParams(window.location.search);
    const invitation = queryParams.get("invitation");
    const catchPhrase = useBrandingStore((state) => state.catchPhrase);
    const displayName = useBrandingStore((state) => state.displayName);
    const tenantLogo = useBrandingStore((state) => state.heroImage); // Access logo from Zustand
    const setTenantLogo = useBrandingStore((state) => state.setHeroImage);
    const setDisplayName = useBrandingStore((state) => state.setDisplayName);
    const setHeroImage = useBrandingStore((state) => state.setHeroImage);
    const setAgentName = useBrandingStore((state) => state.setAgentName);
    const setAgentLogo = useBrandingStore((state) => state.setAgentLogo);

    if (auth.isAuthenticated) {
        // Fetch tenant logo URL and display name once the user is authenticated
        TenantApiClient.get("api/tenants/logo")
            .then((response) => {
     
                if (response.logo) {
                    setTenantLogo(response.logo); // Store the fetched logo URL in Zustand
                    setHeroImage(response.logo); // Set the hero image (logo) in Zustand (for backwards compatibility
                }
                if (response.companyName) {
                    setDisplayName(response.companyName); // Set the display name if provided
                }
            })
            .catch((error) => {

            });
    }

    useEffect(() => {
        if (auth.isAuthenticated && auth.user) {
            const claims = auth.user.profile;
            localStorage.setItem('token', auth.user.access_token);
            localStorage.setItem('applicationId', claims.application_id);
            localStorage.setItem('contactId', claims.contact_id);
        }
    }, [auth.isAuthenticated, auth.user]);

    switch (auth.activeNavigator) {
        case "signInSilent":
            return <div>Signing you in...</div>;
        case "signoutRedirect":
            return <div>Signing you out...</div>;
    }

    if (auth.isLoading) {
        return <div>Loading...</div>;
    }

    if (auth.error) {
        return <div>Error: {auth.error.message}</div>;
    }

    if (auth.isAuthenticated) {
        return (
            <ApplicationProvider>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <Navbar
                        brandImage={tenantLogo}
                        brandDisplayName={displayName}
                        brandCatchPhrase={catchPhrase}
                        style={{
                            position: 'relative',
                            zIndex: 1
                        }}
                    />
                    <main style={{
                        flex: 1,
                        overflow: 'auto', // Allow scrolling in main content
                        position: 'relative',
                        zIndex: 2
                    }}> {/* Main content with higher z-index */}
                        <RouterProvider router={router} />
                    </main>
                </ThemeProvider>
            </ApplicationProvider>
        );
    } else {
        auth.signinRedirect({ extraQueryParams: { invitation } });
        return null;
    }
}

export default App;